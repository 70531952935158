import React from 'react'
import styled from 'styled-components'
import { theme } from '../components/global'
import Layout from '../components/layout'
import SEO from '../components/seo'
import media from '../components/common/mediaQuery'
import { H1, Standard } from '../components/common/type'

const NotFoundPage = () => (
  <Layout>
    <SEO title="You may be lost..." />

    <Row>
      <HeaderWrapper>
        <Logo>
          <Standard as="a" href="/">Friend of a Friend</Standard>
        </Logo>
      </HeaderWrapper>
      <H1>404</H1>
    </Row>
  </Layout>
)

export default NotFoundPage

const Row = styled.div`
  background-color: ${theme.colors.green};
  bottom: 0;
  color: ${theme.colors.bone};
  display: flex;
  height: 100vh;
  left: 0;
  padding: 0 0 40px;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  right: 0;
  position: fixed;
  top: 0;
  z-index: 2000;

  ${media.tablet`
    padding: 0 0 50px;
  `}

  h1 {
    bottom: 20px;
    left: 20px;
    position: absolute;

    ${media.tablet`
      bottom: 50px;
      left: 40px;
    `}
  }
`;

const HeaderWrapper = styled.div`
  align-items: center;
  background-color: ${theme.colors.green};
  border-bottom: 1px solid ${theme.colors.bone};
  display: flex;
  height: 50px;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;

  ${media.tablet`
    border-bottom: 1px solid ${theme.colors.bone};
    height: 80px;
    padding: 0 40px;
  `}
`;

const Logo = styled.div`
  flex: 0 0 auto;
  padding-left: 20px;

  ${media.tablet`
    padding-left: 0;
  `}

  a {
    color: ${theme.colors.bone};

    &:after {
      display: none;
    }
  }
`;
